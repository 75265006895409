<template>
  <div>
    <div class="errormsg" ref="errormsg" v-if="haserror">{{ errorMessage }}</div>
    <h3>{{ getMessage('headline3') }}</h3>
    <label class="dekra-vin"><b>VIN: {{ vin }}</b></label>
    <LoadingSpinner v-if="loading"/>

    <div class="col-md-8 mt-3">
      <div><b>{{ getMessage('vendor') }}*</b></div>
      <div>
        <input class="form-control" type="text" v-validate="'required'" name="vendor" v-model="product.vendor"
               :placeholder="getMessage('vendor')">
        <span> {{ errors.first('vendor') }}</span>
      </div>
    </div>

    <div class="row mt-1">
      <div class="col-md-12 d-flex" style="flex-wrap: wrap;">
        <div class="mt-3">
          <b :class="{ 'error': errors.has('radio_group_1') }"> {{ getMessage('fueltype') }}*</b>
          <div class="d-flex breakonmobile">
            <span> {{ errors.first('radio_group_1') }}</span>
            <div class="form-check d-flex align-items-center">
              <input class="form-check-input" type="radio" v-validate="'required|included:Gasoline,Diesel,Alternative'"
                     v-model="product.fueltype"
                     value="Gasoline" name="fuel_type_group" id="flexRadioDefaultb1">
              <label class="form-check-label" for="flexRadioDefaultb1">
                {{ getMessage('gasoline') }}
              </label>
            </div>
            <div class="form-check d-flex align-items-center" style="margin-left: 30px;">
              <input class="form-check-input" type="radio" v-model="product.fueltype"
                     value="Diesel" name="fuel_type_group" id="flexRadioDefaultb2">
              <label class="form-check-label" for="flexRadioDefaultb2">
                {{ getMessage('diesel') }}
              </label>
            </div>
            <div class="form-check d-flex align-items-center" style="margin-left: 30px;">
              <input class="form-check-input" type="radio" v-model="product.fueltype"
                     value="Alternative" name="fuel_type_group" id="flexRadioDefaultb3">
              <label class="form-check-label" for="flexRadioDefaultb3">
                {{ getMessage('alternative') }}
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-md-12">
        <b :class="{ 'error': errors.has('totalweight_group') }">{{ getMessage('totalweight') }}*</b>
        <div class="d-flex breakonmobile">
          <div class="form-check d-flex align-items-center">
            <input class="form-check-input" type="radio" v-validate="'required|included:lte35,gte35'"
                   v-model="product.totalweight"
                   value="lte35" name="totalweight_group" id="flexRadioDefaultc1">
            <label class="form-check-label" for="flexRadioDefaultc1">
              {{ getMessage('lte35') }}
            </label>
          </div>
          <div class="form-check d-flex align-items-center" style="margin-left: 30px;">
            <input class="form-check-input" type="radio" v-model="product.totalweight"
                   value="gte35" name="totalweight_group" id="flexRadioDefaultc2" checked>
            <label class="form-check-label" for="flexRadioDefaultc2">
              {{ getMessage('gte35') }}
            </label>
          </div>
        </div>
        <span v-show="errors.has('vehicle_type_group')">{{ errors.first('vehicle_type_group') }}<br/></span>
        <span class="col-md-8 mt-3" v-show="errors.has('fuel_type_group')">{{
            errors.first('fuel_type_group')
          }}<br/></span>
        <span class="col-md-8 mt-3" v-show="errors.has('totalweight_group')">{{
            errors.first('totalweight_group')
          }}<br/></span>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-md-12">
        <br/><br/>
        <b>{{ getMessage('additional') }}:</b>
        <div class="row">
          <div class="col-md-4 mt-3">
            <div><b>{{ getMessage('licenseplate') }}</b></div>
            <div>
              <input class="form-control" type="text" v-model="product.licenseplate"
                     :placeholder="getMessage('licenseplate')">
            </div>
          </div>

          <div class="col-md-5 mt-3">
            <div><b>{{ getMessage('firstregistration') }}</b></div>
            <div>
              <input class="form-control" type="date" v-model="product.registration" placeholder="dd.mm.YYYY">
            </div>
          </div>
        </div>
      </div>
    </div>
    <h3>{{ getMessage('headline4') }}</h3>
    <small>{{ getMessage('uploadtext') }}</small>

    <form ref="formfile1">
      <input class="form-control form-control-lg mt-3" v-on:change="uploadFile($event)" name="file1"
             v-validate="'required'" ref="file1" id="formFileLg" type="file" accept=".jpg,.png,.pdf">
    </form>
    <form ref="formfile2">
      <input class="form-control form-control-lg mt-3" v-if="file1 != '' || file2 != ''" v-on:change="uploadFile($event)" name="file2"
             ref="file2" id="formFileLg2" type="file" accept=".jpg,.png,.pdf">
    </form>
    <form ref="formfile3">
      <input class="form-control form-control-lg mt-3" v-if="file2 != '' || file3 != ''" v-on:change="uploadFile($event)" name="file3"
             ref="file3" id="formFileLg3" type="file" accept=".jpg,.png,.pdf">
    </form>
    <form ref="formfile4">
      <input class="form-control form-control-lg mt-3" v-if="file3 != '' || file4 != ''" v-on:change="uploadFile($event)" name="file4"
             ref="file4" id="formFileLg4" type="file" accept=".jpg,.png,.pdf">
    </form>
    <form ref="formfile5">
      <input class="form-control form-control-lg mt-3" v-if="file4 != '' || file5 != ''" v-on:change="uploadFile($event)" name="file5"
             ref="file5" id="formFileLg5" type="file" accept=".jpg,.png,.pdf">
    </form>
    <br/><br/>
    <div class="d-flex breakonmobile mt-1 justify-content-end">
      <div class="mt-2 " style="margin-left: 15px;">
        <button class="btn btn-primary btn-lg " v-on:click="resetPlaque">{{ getMessage('reset') }}</button>
      </div>
      <div class="mt-2 " style="margin-left: 15px;">
        <button class="btn btn-primary btn-lg" v-on:click="addCartItem">{{ getMessage('addplaque') }}</button>
      </div>
      <div class="mt-2 nomarginonmobile" style="margin-left: 15px;">
        <button class="btn btn-primary btn-lg btn-filled" v-on:click="sendCheckout">{{ getMessage('tocart') }}</button>
      </div>


    </div>

  </div>
</template>

<script>
import {mapGetters} from "vuex";
import LoadingSpinner from "@/components/LoadingSpinner";
import router from "@/router";
import moment from "moment-timezone";

export default {
  name: 'shop-vin-view',
  data() {
    return {
      file1: '',
      file2: '',
      file3: '',
      file4: '',
      file5: '',
      loading: false,
      product: {
        "vin": "",
        "licenseplate": "",
        "vendor": "",
        "registration": "",
        "vehicletype": "LKW",
        "fueltype": "",
        "totalweight": "",
        "found": false,
        "file": "",
        "valid": false
      },
      haserror: false,
      errorMessage: ''
    }
  },

  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters({productItemByVin: 'productItemByVin', getMessage: 'getMessage'}),
    validProduct() {

      return !this.product.valid;
    }
  },
  components: {
    LoadingSpinner
  },
  created() {
    this.product = this.productItemByVin(this.vin);
  },
  props: {
    vin: {
      type: String,
    },
  },
  methods: {
    uploadFile(event) {
      let validFileType = false;
      let validFileSize = false;
      let tagName = event.target.name;
      this[tagName] = '';
      this.loading = true;
      this.haserror = false;
      this.errorMessage = '';

      if (event.target.files.length === 0) {
        this[tagName] = '';
        this.$refs[tagName] = null;
        this.loading = false;
        this.$refs['form' + tagName].reset();
        return false;
      }

      if (event.target.files.length > 0) {
        if (event.target.files[0].size <= 1500000) {
          validFileSize = true;
        }
        switch (event.target.files[0].type) {
          case 'image/png':
            validFileType = true;
            break;
          case 'image/jpeg':
            validFileType = true;
            break;
          case 'image/gif':
            validFileType = true;
            break;
          case 'application/pdf':
            validFileType = true;
            break;
          default:
            validFileType = false;
        }
      }

      if (validFileType && validFileSize) {
        this[tagName] = event.target.files[0];
        let formData = new FormData();
        formData.append('file', this[tagName]);
        formData.append('vin', this.vin);
        formData.append('name', tagName);
        var o = {
          formData: formData,
          vin: this.vin
        }
        this.$store.dispatch("uploadFile", o).then(() => {
          this.loading = false;
          this.errorMessage = false;
        }, async error => {
          this[tagName] = '';
          this.haserror = true;
          this.errorMessage = await this.getMessage('invalidfile');
          this.$refs['form' + tagName].reset();
          console.log(error);
          this.loading = false;
        })
      } else {

        this[tagName] = '';
        event.target.files[0].value = null;
        this.$refs[tagName] = null;
        this.haserror = true;
        this.errorMessage = this.getMessage('invalidfile');
        this.loading = false;
        this.$refs['form' + tagName].reset();
        return false;
      }
    },
    addCartItem() {
      this.$validator.validate().then(valid => {
        if (valid) {
          this.loading = true;
          this.haserror = false
          this.product.vehicletype = 'LKW'
          this.$store.dispatch("addCartItem", this.product).then(() => {
            this.loading = false
            this.resetPlaque();
            this.$gtm.trackEvent(
                {
                  event: null, // Event type [default = 'interaction'] (Optional)
                  category: "Product",
                  action: "addToBasket",
                  label: "Add Cart Item",
                  value: 1,
                  noninteraction: false, // Optional
                }
            );

            if (this.$gtm.enabled()) {

              window.dataLayer.push({ecommerce: null});
              window.dataLayer.push({
                event: "add_to_cart",
                ecommerce: {
                  currency: "EUR",
                  value: '36.00',
                  items: [
                    {
                      item_id: 'DEKRA-Plaque',
                      item_name: 'Plaque',
                      price: '36.00',
                      quantity: 1
                    }
                  ]
                }
              });
            }


          }, async error => {
            // Error Message: Vin konnte nicht gefunden werden, bitte überüfen oder manuell ausfüllen
            this.haserror = true;
            this.errorMessage = await this.getMessage('vinalreadyinbasket');
            this.loading = false;
            console.log(error);
          })
        }
      });
    },
    sendCheckout() {
      this.haserror = false
      this.$validator.localize(localStorage.getItem('appLanguage'));
      this.$validator.validate().then(valid => {
        if (valid) {
          if (!this.checkRegistrationDate()) {
            this.haserror = true
            this.errorMessage = this.getMessage('registrationinfuture')
            return;
          }
          this.loading = true;
          this.haserror = false
          this.product.vehicletype = 'LKW'
          this.$store.dispatch("addCartItem", this.product).then(() => {
            this.loading = false

            window.dataLayer.push({
              event: "add_to_cart",
              ecommerce: {
                currency: "EUR",
                value: '36.00',
                items: [
                  {
                    item_id: 'DEKRA-Plaque',
                    item_name: 'Plaque',
                    price: '36.00',
                    quantity: 1
                  }
                ]
              }
            });

            /*
            if (this.$gtm.enabled()) {
              window.dataLayer.push({ecommerce: null});
              window.dataLayer.push({
                event: "addToCart",
                'ecommerce': {
                  'currencyCode': 'EUR',
                  'add': {                                // 'add' actionFieldObject measures.
                    'products': [{                        //  adding a product to a shopping cart.
                      'name': 'Plaque',
                      'id': this.vin,
                      'price': '32.90',
                      'category': 'Plaque',
                      'quantity': 1
                    }]
                  }
                }
              });
            }
            */
            router.push('/' + this.$i18n.locale + '/checkout/address');
          }, async error => {
            // Error Message: Vin konnte nicht gefunden werden, bitte überüfen oder manuell ausfüllen
            this.haserror = true;
            this.$refs.errormsg.innerText = await this.getMessage('vinnotfound');

            console.error(error);
            this.loading = false;
          })
        }
      });
    },
    checkRegistrationDate() {
      if (this.product.registration) {
        let entry = moment(this.product.registration, 'YYYY-MM-DD').unix();
        let now = moment().unix();

        if (entry < now) {
          return true;
        } else {
          return false;
        }
      }
      return true;
    },
    resetPlaque() {
      this.product = {
        "vin": "",
        "licenseplate": "",
        "vendor": "",
        "registration": "",
        "vehicletype": "LKW",
        "fueltype": "",
        "totalweight": "",
        "found": false,
        "file": "",
        "valid": false
      };
      this.file = '';
      this.fileValid = false;

      this.$emit('vin-view-reset', {
        vin: ''
      })
    },
    loadCart() {
      this.haserror = false
      this.$store.dispatch("getCartItems", this.product).then(() => {
        this.loading = false
      }, async error => {
        // Error Message: Vin konnte nicht gefunden werden, bitte überüfen oder manuell ausfüllen
        this.haserror = true;
        this.$refs.errormsg.innerText = await this.getMessage('vinnotfound');

        console.error(error);
        this.loading = false;
      })
    },

  },
  mounted() {
    this.$gtm.trackView('NewProduct', 'currentPath');
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
