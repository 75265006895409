<template>
  <div>
    <MainNavigation />
    <div class="container" v-if="getAppLanguage !== 'de'">
      <div>
        <h2 style="text-align: center;">General Terms and Conditions (GTC) for the Webshop of<br/>DEKRA Austria GmbH</h2>

        <p>(as at 1 June 2022)</p>

        <p>
          Operator of the webshop:<br/>
          DEKRA Austria GmbH<br/>
          Wienerbergstraße 11 / B05<br/>
          1100 Vienna<br/>
          Telephone +43/5/01925<br/>
          Fax +43/5/01925-33<br/>
          www.dekra-austria.at
        </p>

        <p>
          Court of jurisdiction: Vienna<br/>
          Company register no.: FN 236037p<br/>
          UID: ATU57314604<br/>
          Managing Director: Helmut Geil
        </p>

        <p>Information and complaints to: office.at@dekra.com</p>

        <h3>§ 1 Scope of application</h3>
        <p>(1) The following "General Terms and Conditions" (GTC) shall apply in the version valid on the date of the order to all orders between DEKRA Austria GmbH and its customers. Any deviating agreements must be made in writing. By placing an order, the customer agrees to these GTC and is bound by them.</p>
        <p>(2) Should individual provisions of these GTC be invalid in whole or in part due to mandatory statutory provisions (in particular the provisions of the Consumer Protection Act), the remaining provisions of these GTC shall remain valid unchanged.</p>

        <h3>§ 2 Conclusion of contract</h3>
        <p>(1) DEKRA Austria GmbH reserves the right to change the content of the respective range of services at any time. All offers made by DEKRA Austria GmbH are subject to change and are to be understood as an invitation to the customer to make an offer himself.</p>
        <p>(2) An order is only possible if all mandatory fields have been properly completed and the relevant documents have been uploaded legibly. In the event of missing information, illegible documents or other reasons that make it impossible to place an order, we will notify the customer immediately.</p>
        <p>(3) After placing the order, the customer will first receive an order confirmation. The contract itself is not concluded until DEKRA Austria GmbH accepts the order, either by sending an order confirmation by e-mail or directly by dispatching the ordered goods.</p>

        <h3>§ 3 Language of the contract</h3>
        <p>The content of the contract, all other information, customer service and complaint handling are in principle only offered in German.</p>

        <h3>§ 4 Right of withdrawal in distance selling</h3>
        <p>(1) Customers who are consumers according to the Consumer Protection Act may withdraw from the purchase 14 days after delivery of the goods without giving reasons. It is sufficient if the declaration of withdrawal is sent within the period without giving reasons (to be addressed to office.at@dekra.com).</p>
        <p>(2) In the event of withdrawal, the purchase price shall only be refunded concurrently with the return of the goods received from the customer. Both the return of the goods and the refund of the purchase price must be made within 14 days of the declaration of withdrawal or return of the goods. DEKRA Austria GmbH shall bear the costs of the return shipment.</p>
        <p>(3) There is no right of withdrawal (right of rescission) for goods that are manufactured according to customer specifications or are clearly tailored to personal requirements. This applies in particular to the fine dust sticker due to the individual perforation (VIN).</p>

        <h3>§ 5 Prices</h3>
        <p>(1) In principle, the agreed purchase price for the ordered goods is the price shown in the DEKRA Austria GmbH web shop.</p>
        <p>(2) Unless otherwise stated, all prices are quoted as daily gross prices "ex works" including the statutory value added tax and all expenses incurred in connection with the shipment.</p>
        <p>(3) Should export or import duties become due during shipment, these shall be borne by the customer. The prices for the deliveries and services offered do not include any costs charged by third parties.</p>
        <p>(4) In the case of sales to customers outside the EU, no Austrian VAT is due, but the customer undertakes to pay the respective national import duties as well as any national VAT. In the case of sales to entrepreneurs within the EU, no Austrian VAT shall be due upon proof of the UID; these must pay the VAT in their home country (reverse charge procedure).</p>

        <h3>§ 6 Terms of delivery</h3>
        <p>(1) The sticker is usually issued daily. Delivery shall be made within 5-10 working days from the date of the customer's order, but in any case, within 30 days from the date of the order. If an order cannot be accepted (for example due to the unavailability of the goods), the customer will be informed immediately.</p>
        <p>(2) The badge will be issued and delivered based on the information provided by the customer and to the address provided by the customer. In the event of incorrect, incomplete or unclear information provided by the customer (in particular regarding VIN number, exhaust emission class, vehicle type, etc.), the customer shall bear all resulting costs and disadvantages. DEKRA Austria GmbH accepts no liability for incorrect information.</p>

        <h3>§ 7 Terms of payment</h3>
        <p>Payment shall be made immediately after placing the order. Settlement shall be made in euros. When the sticker is issued, the customer receives an electronically generated and electronically transmitted invoice.</p>

        <h3>§ 8 Warranty</h3>
        <p>The warranty shall be provided in accordance with the statutory provisions. If a replacement or an improvement is not possible (not possible, too much effort, unreasonable, delay), the customer is entitled to a price reduction or, if the defect is not insignificant, to cancellation of the contract (redhibition). Compensation for consequential (defect) damage, as well as other property damage, financial loss and damage to third parties against the customer, insofar as it is not a consumer transaction, is excluded.</p>

        <h3>§ 9 Transfer of risk in the case of delivery to consumers according the Consumer Protection Act</h3>
        <p>When the goods are dispatched, the risk of loss of or damage to the goods shall pass to the consumer only as soon as the goods are delivered to the consumer or to a third party designated by the consumer and different from the carrier. If, however, the consumer has concluded the contract of carriage himself/herself without making use of a choice suggested by us, the risk shall pass to the carrier as soon as the goods are handed over to him/her.</p>

        <h3>§ 10 Compensation for damages</h3>
        <p>(1) DEKRA Austria GmbH is liable for damages only in the case of intent and gross negligence. The injured party must prove the existence of slight or gross negligence unless it is a consumer transaction.</p>
        <p>(2) Compensation for consequential (defect) damages and pure financial losses shall be excluded vis-à-vis entrepreneurs.</p>
        <p>(3) DEKRA Austria GmbH cannot be held liable under any circumstances for the correctness of the form or content of the information provided by the customer when ordering the "environmental badges".</p>

        <h3>§ 11 Place of jurisdiction and applicable law</h3>
        <p>(1) This contract shall be governed by Austrian substantive law to the exclusion of the UN Convention on Contracts for the International Sale of Goods. If the contractual partner is a consumer, the mandatory provisions of the law of the state in which he has his habitual residence shall also apply to this contract.</p>
        <p>(2) The court with subject-matter jurisdiction at the registered office of DEKRA Austria GmbH shall have exclusive jurisdiction for disputes.</p>
        <p>If the customer is a consumer within the meaning of the KSchG, the jurisdiction of the court in whose district the customer's domicile, habitual residence or place of employment is located shall be deemed to be established.</p>
        <p>(3) The place of jurisdiction and performance of the contract is the registered office of DEKRA Austria GmbH.</p>
      </div>
    </div>
    <div class="container" v-else-if="getAppLanguage === 'de'">
      <h2 style="text-align: center;">Allgemeine Geschäftsbedingungen (AGB) für den Webshop der<br/>
        DEKRA Austria GmbH</h2>
      <div>
        <p>(Stand 1. Juni 2022)</p>

        <p>
          Betreiber des Webshops:<br/>
          DEKRA Austria GmbH<br/>
          Wienerbergstraße 11 / B05<br/>
          1100 Wien<br/>
          Telefon +43/5/01925<br/>
          Telefax +43/5/01925-33<br/>
          www.dekra-austria.at
        </p>

        <p>
          Gerichtsstand Wien<br/>
          Firmenbuchnr.: FN 236037p<br/>
          UID: ATU57314604<br/>
          Geschäftsführer: Mag. Helmut Geil
        </p>

        <p>Infos, Auskünfte und Beschwerden an: office.at@dekra.com</p>

        <h3>§ 1 Geltungsbereich</h3>
        <p>(1) Die nachstehenden „Allgemeinen Geschäftsbedingungen“ (AGB) gelten in der am Tag der Bestellung gültigen Fassung für sämtliche Bestellungen zwischen DEKRA Austria GmbH und ihren Kunden. Abweichende Vereinbarungen bedürfen der Schriftform. Mit der Abgabe seiner Bestellung erklärt sich der Kunde mit diesen AGB einverstanden und ist an diese gebunden.</p>
        <p>(2) Sollten einzelne Bestimmungen dieser AGB aufgrund zwingender gesetzlicher Vorschriften (insbesondere den Bestimmungen des Konsumentenschutzgesetzes) ganz oder teilweise unwirksam sein, so bleiben die übrigen Bestimmungen dieser AGB unverändert wirksam.</p>

        <h3>§ 2 Vertragsschluss</h3>
        <p>(1) DEKRA Austria GmbH behält sich das Recht vor, das jeweilige Leistungsangebot inhaltlich jederzeit zu verändern. Alle Angebote der DEKRA Austria GmbH sind freibleibend und als Aufforderung an den Kunden zu verstehen, selbst ein Angebot zu legen.</p>
        <p>(2) Eine Bestellung ist nur möglich, wenn alle Pflichtfelder ordnungsgemäß ausgefüllt sind und die entsprechenden Dokumente gut leserlich hochgeladen wurden. Bei fehlenden Angaben, unlesbaren Dokumenten oder anderen Gründen, die eine Bestellung unmöglich machen, teilen wir dies dem Kunden unverzüglich mit.</p>
        <p>(3) Nach der Bestellung erhält der Kunde zunächst eine Bestellbestätigung. Der Vertrag selbst kommt erst durch Annahme der Bestellung durch DEKRA Austria GmbH zustande und zwar entweder durch Absendung einer Auftragsbestätigung mittels E-Mail oder unmittelbar durch Absendung der bestellten Ware.</p>

        <h3>§ 3 Vertragssprache</h3>
        <p>Der Vertragsinhalt, alle sonstigen Informationen, Kundendienst und Beschwerdeerledigung werden grundsätzlich nur in deutscher Sprache angeboten.</p>

        <h3>§ 4 Rücktrittsrecht im Fernabsatz</h3>
        <p>(1) Kunden, die Verbraucher im Sinne des Konsumentenschutzgesetzes sind, können 14 Tage ab Warenübernahme vom Kauf ohne Angabe von Gründen zurücktreten. Es genügt, wenn die Rücktrittserklärung innerhalb der Frist ohne Angabe von Gründen abgesendet wird (zu richten an office.at@dekra.com).</p>
        <p>(2) Im Falle des Rücktritts findet eine Rückerstattung des Kaufpreises nur Zug um Zug gegen Zurückstellung der vom Kunden erhaltenen Waren statt. Sowohl die Rücksendung der Ware und Rückerstattung des Kaufpreises haben binnen 14 Tagen ab Erklärung des Rücktritts bzw. Rücksendung der Ware zu erfolgen. Die Kosten der Rücksendung übernimmt DEKRA Austria GmbH.</p>
        <p>(3) Für Waren, die nach Kundenspezifikationen angefertigt werden oder eindeutig auf die persönlichen Bedürfnisse zugeschnitten sind, besteht kein Widerrufsrecht (Rücktrittsrecht). Dies betrifft speziell die Feinstaubplakette auf Grund der individuellen Lochung (VIN).</p>

        <h3>§ 5 Preise</h3>
        <p>(1) Grundsätzlich gilt jener Kaufpreis für die bestellten Waren als vereinbart, der sich aus dem Webshop der DEKRA Austria GmbH ergibt.</p>
        <p>(2) Soweit nicht anders angegeben, verstehen sich sämtliche Preisangaben als Tagesbruttopreise "ab Werk" einschließlich der gesetzlichen Umsatzsteuer inklusiver aller mit dem Versand entstehenden Spesen.</p>
        <p>(3) Sollten im Zuge des Versandes Export- oder Importabgaben fällig werden, gehen diese zu Lasten des Bestellers. Die Preise für die angebotenen Lieferungen und Leistungen enthalten keine Kosten, die von Dritten verrechnet werden.</p>
        <p>(4) Bei Verkäufen an Kunden außerhalb der EU fällt keine österreichische Umsatzsteuer an, der Kunde verpflichtet sich aber die jeweiligen nationalen Einfuhrabgaben sowie eine etwaige nationale Umsatzsteuer zu entrichten. Bei Verkäufen an Unternehmer innerhalb der EU fällt unter Nachweis der UID keine österreichische Umsatzsteuer an, diese haben die Umsatzsteuer in ihrem Heimatstaat zu entrichten (Reverse Charge Verfahren).</p>

        <h3>§ 6 Lieferbedingungen</h3>
        <p>(1) Die Ausstellung der Plakette erfolgt in der Regel tagesaktuell. Die Lieferung erfolgt innerhalb von 5-10 Werktagen ab der Bestellung des Kunden, aber jedenfalls innerhalb von 30 Tagen ab Bestelldatum. Sollte eine Bestellung nicht angenommen werden können (etwa aufgrund der Nichtverfügbarkeit der Ware), wird dies dem Kunden unverzüglich mitgeteilt.</p>
        <p>(2) Die Ausstellung der Plakette und deren Lieferung erfolgt auf Grund der vom Kunden gemachten Angaben und an die vom Kunden angegebene Adresse. Bei unrichtigen, unvollständigen oder unklaren Angaben durch den Kunden (insbesondere zu VIN Nummer, Abgasklasse, Fahrzeugtyp etc.) trägt dieser alle daraus entstehenden Kosten und Nachteile. DEKRA Austria GmbH übernimmt keinerlei Haftung für unrichtige Angaben.</p>

        <h3>§ 7 Zahlungsbedingungen</h3>
        <p>Die Zahlung erfolgt unmittelbar nach Bestellung. Die Verrechnung erfolgt in Euro. Bei Ausstellung der Plakette erhält der Kunde eine elektronisch erstellte und elektronisch übermittelte Rechnung.</p>

        <h3>§ 8 Gewährleistung</h3>
        <p>Die Gewährleistung erfolgt nach den gesetzl. Bestimmungen. Kommt ein Austausch oder eine Verbesserung nicht in Betracht (nicht möglich, zu hoher Aufwand, unzumutbar, Fristverzug), dann hat der Käufer Anspruch auf Preisminderung bzw. wenn der Mangel nicht geringfügig ist, Anspruch auf Aufhebung des Vertrages (Wandlung). Der Ersatz von (Mangel-) Folgeschäden, sowie sonstigen Sachschäden, Vermögensschäden und Schäden Dritter gegen den Kunden, sofern es sich nicht um ein Verbrauchergeschäft handelt, ist ausgeschlossen.</p>

        <h3>§ 9 Gefahrtragung bei Lieferung an Verbraucher im Sinne des Konsumentenschutzgesetztes</h3>
        <p>Beim Versand der Ware geht die Gefahr für den Verlust oder die Beschädigung der Ware erst auf den Verbraucher über, sobald die Ware an den Verbraucher oder an einen von diesem bestimmten, vom Beförderer verschiedenen Dritten abgeliefert wird. Hat aber der Verbraucher selbst den Beförderungsvertrag geschlossen, ohne dabei eine unsererseits vorgeschlagene Auswahlmöglichkeit zu nützen, so geht die Gefahr bereits mit der Aushändigung der Ware an den Beförderer über.</p>

        <h3>§ 10 Schadenersatz</h3>
        <p>(1) DEKRA Austria GmbH haftet nur für Schäden bei Vorsatz und grober Fahrlässigkeit. Das Vorliegen von leichter bzw. grober Fahrlässigkeit hat, sofern es sich nicht um ein Verbrauchergeschäft handelt, der Geschädigte zu beweisen.</p>
        <p>(2) Der Ersatz von (Mangel-) Folgeschäden und reinen Vermögensschäden ist gegenüber Unternehmern ausgeschlossen.</p>
        <p>(3) Für die formelle oder inhaltliche Richtigkeit der Angaben des Kunden bei der Bestellung der „Umweltplaketten“ kann eine Haftung der DEKRA Austria GmbH keinesfalls übernommen werden.</p>

        <h3>§ 11 Gerichtsstand und anwendbares Recht</h3>
        <p>(1) Auf diesen Vertrag ist österreichisches materielles Recht unter Ausschluss des UN-Kaufrechts anzuwenden. Ist der Vertragspartner Verbraucher, so sind auch die zwingenden Bestimmungen des Rechts des Staates, in dem er seinen gewöhnlichen Aufenthalt hat, auf diesen Vertrag anzuwenden.</p>
        <p>(2) Für Streitigkeiten ist das am Sitz der DEKRA Austria GmbH sachlich zuständige Gericht ausschließlich zuständig.</p>
        <p>Wenn der Kunde Verbraucher im Sinne des KSchG ist, gilt die Zuständigkeit jenes Gerichtes als begründet, in dessen Sprengel der Wohnsitz, der gewöhnliche Aufenthalt oder der Ort der Beschäftigung des Kunden liegt.</p>
        <p>(3) Gerichtsstand und Erfüllungsort des Vertrages ist Sitz der DEKRA Austria GmbH.</p>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import MainNavigation from "@/components/MainNavigation";
import Footer from "@/components/Footer";
import {mapGetters} from "vuex";

export default {
  components: {
    MainNavigation, Footer
  },
  computed: {
    ...mapGetters(['getAppLanguage']),
  },
}
</script>

<style>

</style>