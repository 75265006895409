<template>
  <div>
    <div class="row">
      <div class="col-md-8">
        <div class="dekra-bannerbg" style="height: 100%; position: relative;">
          <router-link target="_self" :to="{ name: 'login', params: { locale: this.$i18n.locale }}">
          <div class="logincircletop">
            Anmelden und bequem auf Rechnung bestellen!
          </div>
          </router-link>
          <div class="errormsg" ref="errormsg" v-if="haserror" style="padding-right: 200px;">{{ errorMessage }}</div>
          <h2 class="dekra-bannerhead">{{ getMessage('headline2') }}</h2>
          <p class="mt-2 hometexttop1">
            {{ getMessage('text1') }}
          </p>
          <p>
            <b><BottomInfoTextTextOne /></b>
          </p>
          <div :hidden="vinsend === true">
          <div>
            <div v-if="getCartCount < 20">
              <div class="d-flex breakonmobile">
                <input class="form-control form-control-lg mt-2" type="text" v-model="vin" :placeholder="getMessage('vin')" @keydown="validateInput">
                <button class="btn btn-lg nomarginonmobile mt-2 btn-primary" type="submit" style="margin-left: 10px;" v-on:click="sendForm">{{ getMessage('next') }}</button>
              </div>
              <div>
                <input type="text" name="dekra" id="dekra" class="cphone" value="">
              </div>
              <div class="mt-3 home-info-panel">
                <div class="home-info-icons">
                  <img src="/img/dekra-home-icon-1.svg" height="65" class="me-2">
                  <img src="/img/dekra-home-icon-2.svg" height="65" class="me-2">
                  <img src="/img/dekra-home-icon-3.svg" height="65">
                </div>
                <div class="home-info-text">
                  <div class="home-info-text-icon">
                    <font-awesome-icon style="color: #11843D;" icon="fa-solid fa-info-circle" />
                  </div>
                  <div>
                    <div style="color: #01853C; font-weight: bold;">
                      <BottomInfoTextTitleFour />
                    </div>
                    <div>
                      <BottomInfoTextTextFour />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <b>{{ getMessage('maxamountitemsincart') }}</b>
            </div>
          </div>
            <div style="margin-top: 2rem; text-align: center;">
              <div
                  v-for="(src, index) in imgs"
                  :key="index"
                  class="pic"
                  @click="() => showImg(index)"
              >
                <img style="width: 100%; cursor: pointer;" :src="src">
              </div>
              <vue-easy-lightbox
                  :visible="visible"
                  :imgs="imgs"
                  :index="index"
                  @hide="handleHide"
              ></vue-easy-lightbox>
            </div>
          </div>
          <LoadingSpinner v-if="loading" />
          <ShopVinView v-if="vinview" v-bind:vin="vin" v-on:vin-view-reset="resetView" />
        </div>
      </div>
      <div class="col-md-4">
        <div class="dekra-bannerbg" style="height: 100%;">
          <div class="d-flex justify-content-center mb-5">
            <img src="../../assets/img/plaketten.png" class="">
          </div>


            <h2>{{ getMessage('infoheadline') }}</h2>
            <a href="#text0">
              <div class="home-section-btn">
                <div class="icon">€</div>
                <div class="text">{{ getMessage('infotitle01') }}</div>
              </div>
            </a>
            <a href="#text1">
              <div class="home-section-btn">
                <div class="icon">§</div>
                <div class="text">{{ getMessage('infotitle02') }}</div>
              </div>
            </a>
            <a href="#text2">
              <div class="home-section-btn">
                <div class="icon"><font-awesome-icon icon="fa-solid fa-map" /></div>
                <div class="text">{{ getMessage('infotitle03') }}</div>
              </div>
            </a>
            <a href="#text3">
              <div class="home-section-btn">
                <div class="icon"><font-awesome-icon icon="fa-solid fa-info" /></div>
                <div class="text">{{ getMessage('infotitle04') }}</div>
              </div>
            </a>
            <a href="#text4">
              <div class="home-section-btn">
                <div class="icon"><font-awesome-icon icon="fa-solid fa-clock" /></div>
                <div class="text">{{ getMessage('infotitle05') }}</div>
              </div>
            </a>
            <a href="#text5">
              <div class="home-section-btn">
                <div class="icon"><font-awesome-icon icon="fa-solid fa-compass" /></div>
                <div class="text">{{ getMessage('infotitle06') }}</div>
              </div>
            </a>
            <a href="#text6">
              <div class="home-section-btn">
                <div class="icon"><font-awesome-icon icon="fa-solid fa-clipboard" /></div>
                <div class="text">{{ getMessage('infotitle07') }}</div>
              </div>
            </a>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingSpinner from "@/components/LoadingSpinner";
import ShopVinView from "@/components/shop/NewProductView";
import { mapGetters } from 'vuex'
import { mapActions } from 'vuex'
import BottomInfoTextTitleFour from "@/components/bottominfotext/TitleFour";
import BottomInfoTextTextFour from "@/components/bottominfotext/TextFour";
import BottomInfoTextTextOne from "@/components/bottominfotext/TextOne";


export default {
  name: 'shop-start-view',
  components: {
    BottomInfoTextTextOne,
    BottomInfoTextTextFour,
    BottomInfoTextTitleFour,
    LoadingSpinner,
    ShopVinView

  },
  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters(['productItemByVin', 'getMessage', 'getAppLanguage', 'getCartCount']),
    ...mapActions(
        {
          check: 'loadVINData'
        }
    )
  },
  data() {
    return {
      errorMessage: '',
      vin: '',
      phone: '',
      haserror: false,
      vinsend: false,
      loading: this.initloading,
      vinview: this.vinview,
      visible: false,
      index: 0,
      imgs: [
        '/img/DEKRA_Karte_Sanierungszonen_Oesterreich.png'
      ]
    }
  },
  props: {
    initloading: {
      type: Boolean,
    },
  },
  methods: {
    validateInput (event) {
      const forbiddenChars = ['@', '#', '$', '%', '^', '&', '*', '(', ')', '.', ' '];
      if (forbiddenChars.includes(event.key)) {
        console.log('Key prevented')
        event.preventDefault();
        return false;
      }
      else {
        var regex = new RegExp("^[a-zA-Z0-9]+$");
        if (regex.test(event.key) && event.key.length === 1) {
          if (this.vin.length >= 17) {
            event.preventDefault();
            return false;
          }
          console.log('YES allowed KEY ' + event.key);
          event.preventDefault();
          this.vin = this.vin + event.key.toUpperCase();
          return true;
        }
      }


      /*
      var r = /[^a-z0-9 .]/gi;
      var validInput = true;


      if(r.test(event.key)) {
        event.preventDefault();
        validInput = false;
      }
      if (this.vin.length >= 17) {
        event.preventDefault();
        validInput = false;
      }

      if (validInput) {
        this.vin = this.vin + event.key.toUpperCase();
      }
      */


    },
    sendForm(){
      this.loading = true
      this.vinview = false
      this.haserror = false

      if(this.vin === ''){
        this.loading = false
        return;
      } else {
        if(this.vin.length !== 17){
          this.loading = false
          this.haserror = true
          this.errorMessage = this.getMessage('vintooshort');
          return;
        }
      }

      this.$gtag.event('validateVin', { method: 'Google' })
          let o = {
            vin: this.vin,
            dekra: document.getElementById('dekra').value
          }
          this.$store.dispatch("getVINData",o).then(async response => {
            this.loading = false
            if (response.status === 200) {
              this.vinsend = true
              this.vinview = true
            }

            if (response.status === 208) {
              this.vinsend = false
              this.vinview = false

              // Error message: VIN ist bereits im Warenkorb (Errorcode V1001)
              this.haserror = true;
              this.errorMessage = await this.getMessage('v1001');
            }

          }, async error => {
            // Error message: Vin konnte nicht gefunden werden, bitte überüfen oder manuell ausfüllen
            this.haserror = true;
            this.loading = false
            this.errorMessage = await this.getMessage('vinnotfound');

            console.error(error);
          })
    },
    resetView(){
      this.loading = false
      this.vinsend = false
      this.vin = ''
      this.vinview = false
    },
    showImg (index) {
      this.index = index
      this.visible = true
    },
    handleHide () {
      this.visible = false
    },
    mounted() {
      this.$gtm.trackView('StartView', 'currentPath');
    }

  },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  ul li {
    margin-bottom: 10px;
  }
</style>
